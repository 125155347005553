import * as React from 'react';
import './Page.css';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Header from '../Header/Header';
import Hero from '../Hero/Hero';
// import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
// import { CartState } from '../CartContext';
import Modal from 'react-modal';
// import Cart from '../Cart';
import PrimaryCurrencyModal from '../PrimaryCurrencyModal';
import { CurrencyBundleProvider } from '../CurrencyBundleContext';

interface PageProps {
  showHero?: boolean;
  wallets: any[];
  setWallets: React.Dispatch<React.SetStateAction<any[]>>;
}

/**
 * Primary UI component for displaying a page
 */
const Page = ({
  children,
  showHero = false,
  wallets,
  setWallets
}: React.PropsWithChildren<PageProps>) => {
  const navigate = useNavigate();
  // const [showCartModal, setShowCartModal] = useState(false);
  const [showPrimaryCurrencyModal, setShowPrimaryCurrencyModal] = useState(false);
  const { isAuthenticated } = useAuth0();
  // const { cartItems } = CartState();

  // const loggedInUser =

  // Determine padding based on showHero prop
  const containerClass = showHero ? 'py-[60px]' : 'py-[0px]';

  // Show Purchase success
  const [isPurchasedSuccessfully, setIsPurchasedSuccessfully] = useState(false);

  const location = useLocation(); // Get the current location object

  useEffect(() => {
    // Function to parse query parameters
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    const canceled = searchParams.get('canceled');

    if (success === 'true') {
      setIsPurchasedSuccessfully(true);
      // setShowCartModal(true);
      if (isAuthenticated) {
        setShowPrimaryCurrencyModal(true);
      }
      // Optionally, clear the query parameters from the URL
      navigate(location.pathname); // Navigates to the same page without query parameters
    } else if (canceled === 'true') {
      setIsPurchasedSuccessfully(false);
      // setShowCartModal(true);
      if (isAuthenticated) {
        setShowPrimaryCurrencyModal(true);
      }
      navigate(location.pathname);
    }
  }, [isAuthenticated, location, navigate]);

  return (
    <div className="page-container flex flex-col justify-between h-screen w-full overflow-auto">
      <Header
        onShopClick={() => navigate('/shop')}
        onEventsClick={() => navigate('/')}
        // onCartClick={() => setShowCartModal(true)}
        onPrimaryCurrencyClick={() => setShowPrimaryCurrencyModal(true)}
        wallets={wallets}
        setWallets={setWallets}
      />
      {showHero && <Hero />}
      <div className={`container mx-auto flex flex-col flex-auto gap-[60px] ${containerClass}`}>
        {children}
      </div>
      {/* <Footer /> */}
      <Modal
        isOpen={showPrimaryCurrencyModal}
        parentSelector={() => document.querySelector('.App')}
        className="PrimaryCurrencyModal__Content primarycurrency-modal-content"
        overlayClassName="CartModal__Overlay ff-primarycurrency-overlay"
        onRequestClose={() => setShowPrimaryCurrencyModal(false)}
        shouldCloseOnOverlayClick={true}
      >
        <CurrencyBundleProvider>
          <PrimaryCurrencyModal showSuccess={isPurchasedSuccessfully} />
        </CurrencyBundleProvider>
      </Modal>
      {/* <Modal
      isOpen={showCartModal}
      parentSelector={() => document.querySelector('.App')}
      className="CartModal__Content ff-modal-content"
      overlayClassName="CartModal__Overlay ff-overlay"
      onRequestClose={() => setShowCartModal(false)}
      shouldCloseOnOverlayClick={true}
    >
      <Cart showSuccess={isPurchasedSuccessfully} />
    </Modal> */}
    </div>
  );
};

export default Page;
