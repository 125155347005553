import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import './UnityGame.css';
import { FaExpand } from 'react-icons/fa';
import Lottie from 'lottie-react';
import loadingAnimation from '../images/lottie/unity-loading.json';

declare global {
  interface Window {
    createUnityInstance: any;
    unityInstance: any;
  }
}

interface UnityGameProps {
  accessToken?: string;
  show?: any;
}

const loadScript = (src: string, type: 'module' | 'text/javascript' = 'text/javascript') => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = type;
    script.onload = () => resolve();
    script.onerror = () => reject(`Failed to load script: ${src}`);
    document.body.appendChild(script);
  });
};

const UnityGame: React.FC<UnityGameProps> = ({ accessToken, show }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const unityInstanceRef = useRef<any>(null);

  useEffect(() => {
    const loadUnityScript = () => {
      const script = document.createElement('script');
      script.src =
        `${show.upc_url}/Build/FutureFestXR.loader.js`;
      script.onload = async () => {
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/agora-extension-virtual-background.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/virtualbackground.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/databuilder.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/clientmanager.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/wglwrapper.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/audioeffects.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/eventmanager.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/engineglobals.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/watermark.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/customvideo.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/agorachannel.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/multichannel.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/audiosystem.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/testing.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/agorautils.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/audiomixing.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/agorartcenginev2.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraRTC_N-4.21.0.js`
        );
        await loadScript(
          `${show.upc_url}/AgoraWebSDK/libs/spatial-audio-main.js`,
          'module'
        );
        await loadScript(
          `${show.upc_url}/TemplateData/hls.min.js`
        );
        await loadScript('https://web-broadcast.live-video.net/1.15.0/amazon-ivs-web-broadcast.js');
        await loadScript(
          `${show.upc_url}/TemplateData/ivs.js`
        );

        window
          .createUnityInstance(
            canvasRef.current,
            {
              arguments: [
                '-playerType',
                'player',
                '-api_url',
                process.env.REACT_APP_API_URL,
                '-env',
                'dev',
                '-jwtMethod',
                'commandline',
                '-worldMapContentId',
                show.world_map_content_id,
                '-worldMapContentUrl',
                show.world_map_content_url,
                '-jwt',
                accessToken
              ],
              dataUrl:
                `${show.upc_url}/Build/FutureFestXR.data`,
              frameworkUrl:
                `${show.upc_url}/Build/FutureFestXR.framework.js`,
              codeUrl:
                `${show.upc_url}/Build/FutureFestXR.wasm`,
              streamingAssetsUrl:
                `${show.upc_url}/StreamingAssets`,
              companyName: 'FutureFest',
              productName: 'FutureFestXR',
              productVersion: '2.0.0'
            },
            (progress: number) => {
              setLoadingProgress(progress * 100);
            }
          )
          .then((unityInstance: any) => {
            unityInstanceRef.current = unityInstance;
            window.unityInstance = unityInstance;
            setIsLoading(false);
            (document.querySelector('#unity-fullscreen-button') as HTMLElement).onclick = () => {
              unityInstance.SetFullscreen(1);
            };
          })
          .catch((message: any) => {
            alert(message);
          });
      };
      document.body.appendChild(script);
    };

    loadUnityScript();

    // Cleanup function to destroy the Unity instance when component unmounts
    return () => {
      if (unityInstanceRef.current) {
        unityInstanceRef.current.Quit();
        unityInstanceRef.current = null;
        window.unityInstance = null;
      }
    };
  }, [accessToken]);

  return (
    <div
      id="unity-container"
      className="unity-desktop flex flex-col items-center justify-center relative h-[80%] mx-auto"
    >
      <Helmet>{/* Helmet content remains unchanged */}</Helmet>
      <div className="relative w-full h-full">
        <canvas ref={canvasRef} id="unity-canvas" className="bg-[#231f20] w-full h-full"></canvas>
        <button
          id="unity-fullscreen-button"
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-75 transition-all duration-200"
        >
          <FaExpand size={20} />
        </button>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <Lottie
              animationData={loadingAnimation}
              loop={true}
              style={{ width: '50%', height: '50%' }}
            />
          </div>
        )}
        {isLoading && (
          <div
            id="unity-loading-bar"
            className="absolute bottom-16 left-4 right-4 h-2 bg-gray-200 rounded-full overflow-hidden"
          >
            <div
              id="unity-progress-bar-full"
              className="h-full bg-blue-500 transition-all duration-300 ease-out"
              style={{ width: `${loadingProgress}%` }}
            ></div>
          </div>
        )}
      </div>
      <div id="unity-mobile-warning" className="text-red-500 mt-4 md:hidden">
        WebGL builds are not supported on mobile devices.
      </div>
    </div>
  );
};

export default UnityGame;
